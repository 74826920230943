class Category {
  constructor (parentId = null) {
    this.id = null
    this.name = ''
    this.parent_id = parentId
    this.children = []
    this.categoryGroups = []
    this.values = []
    this.type = 1
  }
}

export default Category

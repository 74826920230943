<template>
  <v-expansion-panels v-model="panel">
    <v-expansion-panel class="panel">
      <v-expansion-panel-header>
        {{ label }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <slot name="content"></slot>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'BaseExpandPanel',
  props: {
    label: String
  },
  data () {
    return {
      panel: false
    }
  }
}
</script>

<style scoped>
  .v-expansion-panel::before {
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 2%), 0 2px 2px 0 rgb(0 0 0 / 0%), 0 1px 2px 0 rgb(0 0 0 / 17%) !important;
  }
</style>
<template>
  <v-card
    outlined
    class="d-flex justify-space-between pa-2 mb-1"
    style="width: 100%; position: relative;"
  >
    <div
      class="mr-4"
      v-if="categoryName"
    >
      {{categoryName}}
    </div>
    <v-menu
      v-if="canEdit"
      max-height="450px"
      :close-on-content-click="false"
      v-model="isOpenMenuTree"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          v-bind="attrs"
          v-on="on"
          outlined
          color="second"
        >
          {{$t('handbook.chooseCategory')}}
        </v-btn>
      </template>
      <BaseTree
        :value="value.category.id"
        @input="handleSelectedItem($event)"
        :show-actions="false"
        :category-to-filter="typeByLabel('Простой список')"
      />
    </v-menu>
    <v-btn
      class="ml-2"
      :title="$t('base.delete')"
      small
      icon
      @click.passive.stop="$emit('delete')"
    >
      <v-icon
        v-if="canEdit"
        dark
        small
      >mdi-delete
      </v-icon>
    </v-btn>
  </v-card>
</template>

<script>
import serviceDataComputeds from '@/components/mixins/serviceDataComputeds'

export default {
  mixins: [serviceDataComputeds],
  name: 'ComplexCategoryItem',
  components: {
    BaseTree: () => import('@/components/base/BaseTree')
  },
  props: {
    value: Object,
    canEdit: Boolean
  },
  data() {
    return {
      isOpenMenuTree: false
    }
  },
  computed: {
    categoryName () {
      let result = ''
      const name = this.value.category ? this.value.category.name : 'Выберите категорию'
      if (this.value.category?.name && this.value?.category?.parent_id) {
        result = this.$store.getters['handbook/getListItemById'](this.value?.category?.parent_id)?.name + '/' + name
      } else if (this.value.category?.name) {
        result = name
      }
      return result
    }
  },
  methods: {
    handleSelectedItem (data) {
      const category = data ? this.$store.getters['handbook/getListItemById'](data) : null
      this.$emit('input', { ...this.value, category })
      this.isOpenMenuTree = false
    }
  }
}
</script>

<style scoped>

</style>

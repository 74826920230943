<template>
  <div>
    <div
      v-for="(categoryGroup, index) in categoryGroups"
      :key="categoryGroup.id+'-'+index+'-'+categoryGroups.length"
    >
      <ComplexCategoryItem
        :value="categoryGroup"
        @input="handleInput($event, index)"
        @delete="handleDelete(index)"
        :can-edit="canEdit"
      />
    </div>
    <v-btn
      v-if="canEdit"
      class="mt-2"
      small
      color="primary"
      dark
      @click="handleAdd"
    >
      {{$t('handbook.addValueList')}}
    </v-btn>
  </div>
</template>

<script>
import { cleanClone } from '@/components/utils/common'
import CategoryGroup from '@/components/structures/handbook/categoryGroup'
import ComplexCategoryItem from '@/components/views/account/handbook/detail/complexCategory/ComplexCategoryItem'

export default {
  name: 'ComplexCategoryEditor',
  components: { ComplexCategoryItem },
  props: {
    value: Array,
    canEdit: Boolean
  },
  data () {
    return {
      categoryGroups: cleanClone(this.value)
    }
  },
  watch: {
    categoryGroups: {
      handler (newVal, oldVal) {
        this.$emit('input', cleanClone(this.categoryGroups))
      }
    }
  },
  methods: {
    handleAdd () {
      this.categoryGroups.push(new CategoryGroup())
    },
    handleInput (data, index) {
      this.categoryGroups.splice(index, 1, data)
    },
    handleDelete (index) {
      this.categoryGroups.splice(index, 1)
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <v-card flat>
    <v-card-text
      class="d-flex justify-space-between pb-0"
    >
      <v-btn
        v-show="$route.name !== 'constructorDetail'"
        outlined
        class="mb-4"
        :title="$t('base.create')"
        @click="handleCreate(null)"
        x-small
      >
        <v-icon x-small>mdi-plus</v-icon>
        <span class="ml-3">Создать справочник</span>
      </v-btn>
    </v-card-text>
    <v-card-text
      :key="key"
      class="pa-0"
    >
      <v-treeview
        dense
        :items="tree"
        item-key="id"
        item-text="name"
        :open.sync="localOpen"
        activatable
        :search="search"
        :filter="filter"
        :active="localSelected"
        @update:active="handleUpdateSelected"
        return-object
        hoverable
        open-on-click
        transition
      >
        <template v-slot:prepend="{ item, open }">
          <v-icon
            small
            v-if="item.type === 1"
          >
            {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
          </v-icon>
          <v-icon
            v-if="item.type === 2"
            small
          >mdi-view-list-outline
          </v-icon>
          <v-icon
            v-if="item.type === 3"
            small
          >mdi-sitemap
          </v-icon>
        </template>
        <template
          #append="{item}"
          v-if="showActions"
        >
          <v-btn
            v-if="item.type === typeByLabel('Категория') && $can(null, 'editHandbook')"
            class="ml-2"
            :title="$t('base.create')"
            x-small
            :disabled="isHandbookLoading"
            icon
            @click.passive.stop="handleCreate(item.id)"
          >
            <v-icon x-small>mdi-plus</v-icon>
          </v-btn>
          <v-btn
            v-if="$can(null, 'editHandbook')"
            class="ml-2"
            :title="$t('base.edit')"
            x-small
            :disabled="isHandbookLoading"
            icon
            @click.passive.stop="handleEdit(item.id)"
          >
            <v-icon x-small>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            v-if="$can(null, 'editHandbook')"
            class="ml-2"
            :title="$t('base.delete')"
            x-small
            :disabled="isHandbookLoading"
            icon
            @click.passive.stop="handleDelete(item.id)"
          >
            <v-icon x-small>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-treeview>
    </v-card-text>
    <EditDialog
      :key="(editObject.category ? editObject.category.id : 0) +'-'+editObject.parentId+'-'+editDialog"
      :value="editObject.category"
      :is-open.sync="editDialog"
      :parent-id="editObject.parentId"
    />
    <BaseDialog
      v-if="dataDelete.error || dataDelete.deleteCategory"
      role="delete"
      :text="dataDelete.error ? $t('message.shouldDeleteLists') + ':' : ''"
      :item-id="editObject.category ? editObject.category.id : null"
      @confirm="handleCategoryDelete"
      is-cancel-btn
      :is-confirm-btn="false"
      v-model="deleteDialog"
      :max-width="500"
    >
      <template
        v-if="dataDelete.deleteCategory"
        #content
      >
        <BaseAlert
          type="error"
          text="Очистите категорию перед удалением"
        />
      </template>
      <template
        v-else
        #content
      >
        <BaseExpandPanel
          v-if="dataDelete.error && dataDelete.error.attributes"
          label="Аттрибуты"
        >
          <!--note: Вика - если используешь v-for, :key - обязателен!!!-->
          <template #content>
            <v-list class="pa-0">
              <v-list-item
                class="pa-0"
                v-for="(item, index) in dataDelete.error.attributes"
                :key="index"
              >
                &#8226 {{ item.join('/') }}
              </v-list-item>
            </v-list>
          </template>
        </BaseExpandPanel>
        <BaseExpandPanel
          class="mt-2"
          v-if="dataDelete.error && dataDelete.error.categories"
          label="Категории"
        >
          <template #content>
            <v-list class="pa-0">
              <v-list-item
                class="pa-0"
                v-for="(item, index) in dataDelete.error.categories"
                :key="index"
              >
                &#8226 {{ item.join('/') }}
              </v-list-item>
            </v-list>
          </template>
        </BaseExpandPanel>
      </template>
    </BaseDialog>
    <BaseDeleteConfirm
      v-else
      :id="editObject.category ? editObject.category.id : null"
      :isDialog="deleteDialog"
      entity-text="сущность и все ее содержимое"
      @closeDialog="deleteDialog = false"
      @delete="handleCategoryDelete(editObject.category ? editObject.category.id : null)"
    >
    </BaseDeleteConfirm>
  </v-card>
</template>

<script>
import { cleanClone, deepEqual } from '@/components/utils/common'
import EditDialog from '@/components/views/account/handbook/detail/EditDialog'
import serviceDataComputeds from '@/components/mixins/serviceDataComputeds'
import BaseDialog from '@/components/base/BaseDialog'
import loading from '@/components/mixins/loading'
import BaseExpandPanel from '@/components/base/UI/BaseExpandPanel'
import BaseDeleteConfirm from '@/components/base/BaseDeleteConfirm'
import BaseAlert from '@/components/base/UI/BaseAlert'

export default {
  mixins: [serviceDataComputeds, loading],
  name: 'BaseTree',
  components: { BaseAlert, BaseDeleteConfirm, BaseExpandPanel, BaseDialog, EditDialog },
  props: {
    value: Number,
    showActions: Boolean,
    categoryToFilter: Number,
    regionBtn: { type: Boolean, default: false }
  },
  data () {
    return {
      search: null,
      localSelected: [],
      localOpen: [],
      key: 0,
      editDialog: false,
      deleteDialog: false,
      editObject: {
        category: null,
        parentId: null
      },
      dataDelete: {
        error: false,
        deleteCategory: false
      }
    }
  },
  computed: {
    tree () {
      this.key++
      return this.$store.getters['handbook/getTree']
    },
    filter () {
      return (item) => {
        return this.categoryToFilter ? item.type === this.categoryToFilter : true
      }
    }
  },
  watch: {
    value: {
      async handler (newVal, oldVal) {
        if (!deepEqual(newVal, oldVal) && this.value) {
          await this.$nextTick(() => {
            let activeObject = this.$store.getters['handbook/getListItemById'](this.value)

            if (activeObject) {
              this.localSelected = [activeObject]
              this.localOpen.push(this.$store.getters['handbook/getListItemById'](activeObject.parent_id))
            }
          })
        }
        return true
      },
      immediate: true
    },
    tree: {
      async handler () {
        return this.$nextTick(() => {
          if (this.localSelected.length && !this.$store.getters['handbook/getListItemById'](this.localSelected[0].id)) {
            this.localSelected = []
            this.$emit('input', null)
          }
        })
      },
      deep: true
    }
  },
  methods: {
    handleUpdateSelected (data) {
      if (!deepEqual(data, this.localSelected)) {
        if (data.length && (data[0].type === this.typeByLabel('Простой список') || data[0].type === this.typeByLabel('Составной список'))) {
          this.localSelected = data
        } else {
          this.localSelected = cleanClone(this.localSelected)
        }
        this.$emit('input', this.localSelected.length ? this.localSelected[0].id : null)
      }
    },
    async handleCategoryDelete (data) {
      return this.$store.dispatch('handbook/deleteCategory', data)
    },
    handleEdit (data) {
      this.editObject = {
        parentId: null,
        category: this.$store.getters['handbook/getListItemById'](data)
      }
      this.editDialog = true
    },
    handleCreate (data) {
      this.editObject = {
        parentId: data,
        category: null
      }
      this.editDialog = true
    },
    //note: Вика обрати внимание на логику основного метода и проверок
    async handleDelete (data) {
      if (!this.isHandbookLoading) {
        this.$store.commit('handbook/setLoadingState', true)
        this.editObject.category = this.$store.getters['handbook/getListItemById'](data)
        if (this.$route.name === 'handbook') {
          await this.canDelete(this.editObject.category.id)
        }
        this.deleteDialog = true
        this.$store.commit('handbook/setLoadingState', false)
      }
      return true
    },
    async canDelete (id) {
      this.dataDelete.deleteCategory = false
      this.dataDelete.error = await this.$store.dispatch('server/get', {
        url: '/handbook/check/' + id
      })
      if (this.dataDelete.error === null) {
        let category = this.tree.find(item => item.id === id)
        if (category && category.children?.length) {
          this.dataDelete.deleteCategory = true
        }
      }
      return true
    }
  },
  mounted () {
    this.search = 'new'
  }
}
</script>

<style scoped>

</style>

import Category from '@/components/structures/handbook/category'

class CategoryGroup {
  constructor () {
    this.id = null
    this.category = new Category()
  }
}

export default CategoryGroup

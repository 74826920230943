<template>
  <BaseDialog
    :label="$t('message.enterNameHandbook')"
    :value="isDialogOpen"
    :max-width="600"
    @input="isDialogOpen = false"
    is-cross-close
    :is-confirm-btn="isCanSave"
    :text-confirm="$t('base.save')"
    @confirm="handleSave"
    :isLoading="isLoading"
  >
    <template #content>
      <v-card-text class="mt-4">
        <v-text-field
          :placeholder="$t('message.enterNameHandbook')"
          outlined
          dense
          :autofocus="isDialogOpen"
          v-model="category.name"
          hide-details
        ></v-text-field>
      </v-card-text>
      <v-card-text>
        <div
          v-if="category.type === typeByLabel('Составной список')"
        >
          <div class="title mb-2">{{ $t('handbook.complexValues') }}</div>
          <ComplexCategoryList
            v-model="category.categoryGroups"
            :can-edit="!category.id"
          />
        </div>
        <div>
          <v-radio-group
            v-model="category.type"
            :disabled="!!category.id"
            class="d-flex mt-0"
            dense
          >
            <v-radio
              :label="$t('handbook.category')"
              :value="typeByLabel($t('handbook.category'))"
            ></v-radio>
            <v-radio
              :label="$t('handbook.values')"
              :value="typeByLabel($t('handbook.values'))"
            ></v-radio>
            <v-radio
              :label="$t('handbook.complexValues')"
              :value="typeByLabel($t('handbook.complexValues'))"
            ></v-radio>
          </v-radio-group>
        </div>
      </v-card-text>
      <p
        v-if="category.type === 3 && !isCanSave"
        class="ma-0 grey--text"
      >
        {{$t('message.conditionCreateComplexHandbook')}}
      </p>
    </template>
  </BaseDialog>
</template>

<script>
import { cleanClone } from '@/components/utils/common'
import Category from '@/components/structures/handbook/category'
import serviceDataComputeds from '@/components/mixins/serviceDataComputeds'
import ComplexCategoryList from '@/components/views/account/handbook/detail/complexCategory/ComplexCategoryList'
import BaseDialog from '@/components/base/BaseDialog'
import BasePreloader from '@/components/base/UI/BasePreloader'

export default {
  components: { BasePreloader, BaseDialog, ComplexCategoryList },
  mixins: [serviceDataComputeds],
  name: 'EditDialog',
  props: {
    isOpen: Boolean,
    value: Object,
    parentId: Number
  },
  data () {
    let category = cleanClone(this.value)
    if (!category) {
      category = new Category(this.parentId)
    }
    return {
      isLoading: false,
      category
    }
  },
  computed: {
    isDialogOpen: {
      get () {
        return this.isOpen
      },
      set (val) {
        this.$emit('update:isOpen', val)
      }
    },
    isCanSave () {
      const isNameCategory = !!this.category.name.length > 0
      let result = false
      if (this.category.type === 3) {
        const countSimpleLists = _.filter(this.category.categoryGroups, function (item) {
          return item?.category?.type === 2
        }).length >= 2
        for (let i = 0; i <= this.category.categoryGroups.length - 1; i++) {
          result = true
          if (!(countSimpleLists) || !isNameCategory || !(this.category.categoryGroups[i].category.name.length > 0)) {
            // условие: есть название и минимум 2 списка
            // последняя проверка запрещает добавление пустой категории (или списка?)
            result = false
            i = this.category.categoryGroups.length - 1
          }
        }
      } else if (isNameCategory && this.category.type !== 3) {
        result = true
      }
      return result
    }
  },
  methods: {
    async handleSave () {
      if (!this.isLoading) {
        this.isLoading = true
        this.category = await this.$store.dispatch('handbook/updateCategory', this.category)
        this.$store.commit('handbook/updateCategory', cleanClone(this.category))
        this.isLoading = false
        this.isDialogOpen = false
      }
      return true
    }
  }
}
</script>

<style scoped>

</style>
